import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import getLocalizedData from '../utils/getLocalizedData';
import PageWrap from '../components/PageLayout/PageWrap';
import SectionComponent from '../components/pageSections/PageSectionComponent';

const colorShape = PropTypes.shape({
  color: PropTypes.shape({
    hex: PropTypes.string,
  }),
});

const propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
  data: PropTypes.shape({
    page: PropTypes.shape({
      id: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      bookingQuery: PropTypes.string,
      pageMainTextColor: colorShape,
      pageMainBgColor: colorShape,
      pageMainAccentColor: colorShape,
      // pageTheme: PropTypes.shape({
      //   name: PropTypes.string,
      // }),
      pageMetadata: PropTypes.shape({
        titleTag: PropTypes.any,
        metaDescription: PropTypes.any,
        seoImage: PropTypes.any,
      }),
      pageBuilder: PropTypes.arrayOf(PropTypes.any).isRequired,
    }),
  }).isRequired,
};
export const query = graphql`
  query AllPages($id: String!) {
    pageTheme: sanityNewPage(id: { eq: $id }) {
      pageMainBgColor {
        color {
          hex
        }
      }
      pageMainAccentColor {
        color {
          hex
        }
      }
      pageMainTextColor {
        color {
          hex
        }
      }
    }
    page: sanityNewPage(id: { eq: $id }) {
      id
      updatedAt: _updatedAt
      bookingQuery
      title
      pageMetadata {
        titleTag {
          no
          en
        }
        metaDescription {
          no
          en
        }
        seoImage {
          alt {
            no
            en
          }
          asset {
            id
          }
        }
        noindex
      }
      pageBuilder: _rawPageBuilder(resolveReferences: { maxDepth: 10 })
      SubNavigation: _rawSubNavigation(resolveReferences: { maxDepth: 10 })
      subNavigation {
        _updatedAt
      }
      referencedDocuments: pageBuilder {
        ... on SanityInfoCardSection {
          infoCards {
            ... on SanityInfoCard {
              _updatedAt
            }
            ... on SanityUniversalCard {
              _updatedAt
            }
          }
        }
        ... on SanityImageCardSection {
          imageCardReference {
            _updatedAt
          }
        }
        ... on SanityFaqCatalogue {
          categories {
            _updatedAt
          }
        }
      }
    }
  }
`;

function PageTemplate({ data: { page, pageTheme }, pageContext }) {
  const { locale } = pageContext;
  const { bookingQuery, pageMetadata, pageBuilder, SubNavigation } = page;

  const localizedPageBuilder = getLocalizedData(pageBuilder, locale);
  const localizedPagemetadata = getLocalizedData(pageMetadata, locale);
  const localizedSubNavigation = getLocalizedData(SubNavigation, locale);

  const pageContainsHubspot = useMemo(
    () => pageBuilder.some(({ _type }) => _type === 'hubspotForm'),
    [pageBuilder],
  );

  return (
    <>
      {pageContainsHubspot && (
        <Helmet>
          <script
            type="text/javascript"
            src="//js.hsforms.net/forms/shell.js"
          />
        </Helmet>
      )}
      <PageWrap
        bookingQuery={bookingQuery}
        seoData={localizedPagemetadata}
        theme={pageTheme}
        subNavigation={localizedSubNavigation}
      >
        {localizedPageBuilder.map((section) => (
          <SectionComponent
            key={section._key}
            componentType={section._type}
            locale={locale}
            {...section}
          />
        ))}
      </PageWrap>
    </>
  );
}

PageTemplate.propTypes = propTypes;
export default PageTemplate;
